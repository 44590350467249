<template>
  <router-link :to="{ name: 'Homepage' }">
    <div class="logo">
      <img :src="require('@/assets/logo-smart-vacances.png')" />
      <label class="primary-color-label">Smart Vacances</label>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'ResponsiveLogo',
};
</script>

<style scoped>
.logo {
  width: 100%;
  height: 35px;
  font-family: 'aquatico';
  color: white;
  font-weight: 800;
  font-size: 16px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.logo img {
  height: 30px;
  margin-right: 3px;
}

.logo label {
  cursor: pointer;
}

@media (min-width: 768px) {
  .logo {
    width: 320px;
    height: 50px;
    font-size: 27px;
  }

  .logo img {
    height: 45px;
    margin-right: 7px;
  }
}
</style>
