export default {
  mounted(el, binding) {
    const { value = {} } = binding;
    const {
      threshold = 0.1,
      animationClass = 'fade-in-visible',
      rootMargin = '0px 0px -50px 0px',
    } = value;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            el.classList.add(animationClass);
            observer.unobserve(el);
          }
        });
      },
      { threshold, rootMargin },
    );

    observer.observe(el);
  },
};
