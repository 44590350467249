import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vue3GoogleLogin from 'vue3-google-login';
import store from './store';
import mitt from 'mitt';
const emitter = mitt();
import fadeScroll from './directives/fadescroll';

const app = createApp(App).use(router).use(store).use(vue3GoogleLogin, {
  clientId:
    '497968535728-krhp8ej3p18f7gb3g4ggg27cbo69sti4.apps.googleusercontent.com',
});

app.config.globalProperties.emitter = emitter;

app.directive('fadescroll', fadeScroll);

app.mount('#app');
