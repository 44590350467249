<template>
  <header :class="['sticky-header', { detached: isHeaderDetached }]">
    <div class="logo-container">
      <ResponsiveLogo id="responsive-logo" />
    </div>
    <ButtonWithGradient
      v-show="
        $route.name === 'SpecifiedDestinationOverview' &&
        shouldDisplayTripCreationBtn
      "
      class="choose-destination-btn"
      message="Choisir cette destination"
      @click="$emit('create-trip')"
    />

    <button
      v-if="!isAuthenticated"
      class="connection-btn"
      @click="openLoginModal"
    >
      Se connecter
    </button>
    <router-link
      v-else
      :to="{ name: 'UserProfile' }"
      class="profile-redirect-link"
    >
      <img :src="userImage" alt="user logo" class="user-logo" />
    </router-link>

    <LoginModal v-if="isLoginModalOpened" is-open @close="closeLoginModal" />
  </header>
</template>

<script>
import ResponsiveLogo from '@/components/Base/ResponsiveLogo.vue';
import LoginModal from '@/components/Modal/LoginModal.vue';
import { mapGetters } from 'vuex';
import ButtonWithGradient from '@/components/Base/ButtonWithGradient.vue';

export default {
  name: 'HeaderSticky',
  components: {
    ButtonWithGradient,
    LoginModal,
    ResponsiveLogo,
  },
  emits: ['create-trip'],
  data() {
    return {
      userImage: require('@/assets/user.png'),
      isLoginModalOpened: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user', 'shouldDisplayTripCreationBtn']),
  },
  mounted() {
    this.emitter.on('show-login-modal', () => {
      this.openLoginModal();
    });
  },
  methods: {
    openLoginModal() {
      this.isLoginModalOpened = true;
    },
    closeLoginModal() {
      this.isLoginModalOpened = false;
    },
  },
};
</script>

<style scoped>
.sticky-header {
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  position: sticky;
  padding: 10px 5px;
  background-color: #fff;
  transition: top 0.3s ease;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
}

.logo-container {
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

.user-logo {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.profile-redirect-link {
  height: 35px;
}

.choose-destination-btn {
  display: none;
}

.connection-btn {
  background-color: white;
  color: var(--primaryColor);
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--primaryColor);
}

.connection-btn:hover {
  background: linear-gradient(to right, var(--primaryColor), var(--hoverColor));
  color: #fff;
  border: 1px solid var(--primaryColor);
}

@media (min-width: 768px) {
  .sticky-header {
    justify-content: center;
    padding: 10px;
  }
  .user-logo {
    width: 40px;
    height: 40px;
  }

  .profile-redirect-link {
    height: 40px;
  }

  .connection-btn {
    padding: 10px 20px;
    font-size: 17px;
  }

  .choose-destination-btn {
    display: block;
    margin-right: 15px;
  }
}
</style>
