<template>
  <ViewLayout>
    <div class="profile-container main-container">
      <div v-if="tabs">
        <h1 class="title-with-separator">Vos contenus générés</h1>
        <TabSelector :tabs="tabs">
          <template #tab-0>
            <div v-if="userProfile?.userTrips">
              <h3>Vos Voyages</h3>
              <div v-for="trip in userProfile.userTrips.trips" :key="trip.id">
                <router-link
                  :to="{
                    name: 'Trip',
                    params: {
                      tripId: trip.id,
                      cityName: cityNameToUrl(trip.destinationCity),
                    },
                  }"
                >
                  <TripCard :trip="trip" />
                </router-link>
              </div>
            </div>
            <div v-else>
              <p>Vous n'avez pas encore créé ou planifié de voyage</p>
              <p>
                Vous pouvez créer des voyages à partir
                <label class="primary-color-label">
                  de vos destinations suggérées
                </label>
              </p>
            </div>
          </template>
          <template #tab-1>
            <div v-if="userProfile?.userDestinationsSuggestions">
              <div
                v-for="destinationSuggestion in userProfile
                  .userDestinationsSuggestions.destinationsSuggestions"
                :key="destinationSuggestion.id"
              >
                <div>
                  <h3>Pour les critères :</h3>
                  <p class="criteria">
                    {{ destinationSuggestion.criteria }}
                  </p>
                </div>

                <DestinationsList
                  :destinations="destinationSuggestion.destinations"
                />
              </div>
            </div>
            <div v-else>
              <p>Vous n'avez pas encore de suggestions de destination</p>

              <router-link :to="{ name: 'GenerateDestinationsSuggestion' }">
                <ButtonWithGradient message="Créer un nouveau voyage" />
              </router-link>
            </div>
          </template>
          <template #tab-2>
            <div>
              <h3>Third Tab Content</h3>
            </div>
          </template>
        </TabSelector>
      </div>
      <div v-else>
        <p>Chargement...</p>
      </div>

      <!--    <h1 class="title-with-separator">-->
      <!--      Vos informations personnelles-->
      <!--    </h1>-->

      <button class="logout-btn" @click="applyLogout">Se déconnecter</button>
    </div>
  </ViewLayout>
</template>

<script>
import ViewLayout from '@/ViewLayout.vue';
import TabSelector from '@/components/Base/TabSelector.vue';
import smartVacancesApiClient from '@/SmartVacancesApiClient';
import DestinationsList from '@/components/DestinationsList.vue';
import ButtonWithGradient from '@/components/Base/ButtonWithGradient.vue';
import { mapActions } from 'vuex';
import TripCard from '@/components/Cards/TripCard.vue';
import { cityNameToUrl } from '@/utils/city';

export default {
  name: 'UserProfile',
  components: {
    TripCard,
    ButtonWithGradient,
    DestinationsList,
    TabSelector,
    ViewLayout,
  },
  data() {
    return {
      tabs: [
        {
          label: 'Vos voyages',
          count: 0,
          content: '1',
        },
        {
          label: 'Vos suggestions de destination',
          count: 0,
          content: '2',
        },
        // {
        //   label: 'Categ 3',
        //   count: 3,
        //   content: '3',
        // },
      ],
      userProfile: undefined,
    };
  },
  mounted() {
    this.fetchTabsData();
  },
  methods: {
    cityNameToUrl,
    ...mapActions(['applyLogout']),
    async fetchTabsData() {
      try {
        const response = await smartVacancesApiClient.get('v1/user/profile');

        this.userProfile = response.data.data;
        this.tabs[0].count = this.userProfile.userTrips.count;
        this.tabs[1].count = this.userProfile.userDestinationsSuggestions.count;
      } catch (error) {
        const responseHttpCode = error.response.status;
        if (responseHttpCode === 403) {
          this.applyLogout();
        }
      }
    },
  },
};
</script>

<style scoped>
.profile-container {
  margin: 8px;
  text-align: center;
}

.criteria {
  font-style: italic;
}

.logout-btn {
  background-color: indianred;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif;
  font-size: 17px;
  line-height: 2.5;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  transition:
    box-shadow 0.2s ease-in-out,
    background-color 0.2s ease;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  margin: 5px;
}
</style>
