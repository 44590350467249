<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
@import 'variables.css';

@font-face {
  font-family: 'aquatico';
  src: url('assets/fonts/Aquatico-Regular.otf') format('truetype');
}

body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.error-message {
  color: var(--errorColor);
}

.title-with-separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.title-with-separator::before,
.title-with-separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--primaryColor);
  margin: 0 10px;
}

a {
  color: initial;
  text-decoration: none;
}

.primary-color-label {
  background: linear-gradient(
    to bottom right,
    var(--hoverColor),
    var(--primaryColor)
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

#smartvacances-logo {
  width: 300px;
  border-radius: 5px;
}

.centered-paragraph {
  max-width: 330px;
}

.editable-field {
  cursor: pointer;
  padding: 2px 5px;
  border: 1px dashed var(--primaryColor);
  border-radius: 5px;
  transition:
    background-color 0.3s,
    border-color 0.3s;
  background-color: rgba(255, 138, 59, 0.07);
}

.editable-field:hover {
  background-color: #ffede2;
}

.main-container {
  overflow: hidden;
}

@media (min-width: 1024px) {
  .centered-paragraph {
    max-width: 650px;
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  #app {
    margin: 0 auto;
  }

  .main-container {
    width: 1200px;
    justify-self: center;
  }
}
</style>
