<template>
  <ViewLayout>
    <div id="generate-destinations-container" class="main-container">
      <PromptComponent msg="" />
    </div>
  </ViewLayout>
</template>

<script>
import ViewLayout from '@/ViewLayout.vue';
import PromptComponent from '@/components/Prompt.vue';

export default {
  name: 'HomepageView',
  components: {
    ViewLayout,
    PromptComponent,
  },
};
</script>

<style>
#generate-destinations-container {
  margin: 8px;
}
</style>
