<template>
  <div class="date-container">
    <span class="date editable-field" @click="openDatepicker">
      {{ formatDate(departureDate) }}
    </span>
    au
    <span class="date editable-field" @click="openDatepicker">
      {{ formatDate(returnDate) }}
    </span>

    <Datepicker
      v-if="isDatepickerOpen"
      v-model="selectedDates"
      range
      :start-date="departureDate"
      :end-date="returnDate"
      :min-date="new Date()"
      :format="format"
      :show-footer="true"
      confirm-text="Confirmer"
      class="custom-datepicker"
      inline
      :month-change-on-scroll="false"
      auto-apply
      @update:model-value="handleDateChange"
      @click-outside="closeDatepicker"
    />
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'DateRangePicker',
  components: {
    Datepicker,
  },
  props: {
    departureDate: {
      type: String,
      required: true,
    },
    returnDate: {
      type: String,
      required: true,
    },
  },
  emits: ['date-range-selected'],
  data() {
    return {
      isDatepickerOpen: false,
      selectedDates: [this.departureDate, this.returnDate],
      format: 'dd MMMM yyyy',
      frLocale: {
        days: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        firstDayOfWeek: 1,
      },
    };
  },
  methods: {
    openDatepicker() {
      this.isDatepickerOpen = true;
    },
    closeDatepicker() {
      this.isDatepickerOpen = false;
    },
    handleDateChange(dates) {
      this.selectedDates = dates;
      if (dates.length === 2) {
        const [departureDate, returnDate] = dates;
        this.$emit('date-range-selected', {
          departureDate,
          returnDate,
        });
        this.closeDatepicker();
      }
    },
    formatDate(dateStr) {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      const date = new Date(dateStr);
      return date.toLocaleDateString('fr-FR', options);
    },
  },
};
</script>

<style>
.date {
  font-weight: 800;
  padding: 1px 3px;
  border-radius: 5px;
  cursor: pointer;
}

.date-container {
  margin: 10px;
  position: relative;
}

.custom-datepicker {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 300px;
}

.custom-datepicker .dp__input_wrap {
  display: none;
}

.custom-datepicker .dp__action_button {
  font-family: inherit;
  font-size: 14px;
}

.custom-datepicker .dp__action_button:last-child::after {
  content: 'Confirmer';
  visibility: visible;
}

.dp__range_start,
.dp__range_end {
  border: none;
  background: linear-gradient(
    to bottom right,
    var(--hoverColor),
    var(--primaryColor)
  );
}
</style>
